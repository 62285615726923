import React from "react";
import "./gallerylogo.scss";
import tenant1images from "../../../media/logo/tenant/tenant1";
import tenant2images from "../../../media/logo/tenant/tenant2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const gallerylogo = () => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        lazyload: true, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 4, // Show only 1 slide on mobile (optional)
          swipeToSlide: true,
          lazyload: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="container-gallery">
      <div className="judul-gallery">A Community of Thriving Tenants</div>
      <div className="gallerylogo">
        <Slider {...settings}>
          {tenant1images.map((image, index) => (
            <div>
              <img
                className="kartugambar01"
                key={index}
                src={image}
                alt="Podomoro"
              />
            </div>
          ))}
        </Slider>
        <Slider {...settings}>
          {tenant2images.map((image, index) => (
            <div>
              <img
                className="kartugambar01"
                key={index}
                src={image}
                alt="Podomoro"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default gallerylogo;
