import React from "react";
import Promoimage from "../../media/promo-new.png";
import "./promo.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const promo = () => {
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=+6285121010886&text=Halo%20Nita,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20ini%20(promo)%20https://marketing-bukitpodomorojakarta.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-promo">
      <div className="promo">
        <div id="promo" className=" container-listpromo">
          <div className="containercontent">
            <div id="promo" className="judul-promo">
              PROMO <div className="beda">BUKIT PODOMORO JAKARTA</div>
            </div>
            <div className="pointpenawaran">
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>
                  &nbsp;&nbsp;SIAP HUNI 2025
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>&nbsp;&nbsp;Free PPN</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>&nbsp;&nbsp;Cicil DP 0%</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>
                  &nbsp;&nbsp;Discount Up to 5%
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>
                  &nbsp;&nbsp;Free Biaya Akad
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>
                  &nbsp;&nbsp;Bonus Mobil Listrik*
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>
                  &nbsp;&nbsp;Free Semi Furnished*
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>&nbsp;&nbsp;Free Canopy</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>
                  &nbsp;&nbsp;Free Kitchen Set
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>
                  &nbsp;&nbsp;Free Water Heater
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>
                  &nbsp;&nbsp;Free Smartdoor Lock
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheck} />
                <span style={{ color: "black" }}>
                  &nbsp;&nbsp;Free Lifetime Membership Clubhouse
                </span>
              </div>
            </div>
            <div className="disclaimer">*Disclaimer</div>
            <div className="button-wa-promo">
              <button onClick={wapromo} className="whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} /> Get More Promo
              </button>
            </div>
          </div>
          <div className="containergambar">
            <img className="penawarangambar" src={Promoimage} alt="hardsell" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default promo;
