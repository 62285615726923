import React from "react";
import "./newlaunch.scss";
import image from "./img-nwlaunch.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const newlaunch = () => {
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=+6285121010886&text=Halo%20Nita,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20ini%20(newlaunch)%20https://marketing-bukitpodomorojakarta.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="newlaunch">
      <div className="container-newlaunch">
        <div className="judul-newlaunch">
          NEW LAUNCHING <br />
          BUKIT PODOMORO JAKARTA
        </div>
        <div className="img-newlaunch">
          <img src={image} alt="new-launch-bukit-podomoro-jakarta" />
        </div>
        <div className="button-wa-promo">
          <button onClick={wapromo} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Get More Promo
          </button>
        </div>
      </div>
    </div>
  );
};

export default newlaunch;
